<template>
    <admin-dashboard-layout :loading="loading">
        <v-card class="mx-15 px-15">
            <v-card-title>
                <v-row>
                    <v-col v-text="video.name"/>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.videos.index'}">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-select
                        v-model="video.language"
                        :items="languages"
                        item-text="label"
                        item-value="code"
                        outlined
                        dense
                        label="Language">
                        <template slot="selection" slot-scope="data">
                            {{ $t('languages.' + data.item.code) }}
                        </template>
                        <template slot="item" slot-scope="data">
                            {{ $t('languages.' + data.item.code) }}
                        </template>
                    </v-select>
                    <v-text-field outlined dense label="Name"  v-model="video.name"></v-text-field>
                    <v-row>
                        <v-col md="6">
                            <v-textarea outlined dense label="Description" v-model="video.description"/>
                            <v-textarea outlined dense label="Notes" v-model="video.notes"/>
                            <h2 class="ml-3" v-text="'Video'"/>
                            <vue-dropzone
                                ref="myVueDropzone" id="dropzone"
                                :options="dropzoneOptions"
                                @vdropzone-success="handleSetUrl"/>
                            <br>
                            <h2 class="ml-3" v-text="'Thumbnail'"/>
                            <vue-dropzone
                                ref="thumbnailVueDropzone" id="thumbnailDropzone"
                                :options="thumbnailDropzoneOptions"
                                @vdropzone-success="handleSetThumbnailUrl"
                                @vdropzone-max-files-exceeded="handleExceededFilesLimit"/>
                            <v-text-field v-model="video.rank" outlined dense label="Rank" class="mt-7"/>
                            <v-select
                                v-model="video.tags"
                                outlined
                                dense
                                label="Tags"
                                item-text="name"
                                item-value="id"
                                :multiple="true"
                                :items="tags"/>
                            <v-select
                                v-model="video.industry_id"
                                outlined
                                dense
                                label="Industry"
                                item-text="name"
                                item-value="id"
                                :items="industries"/>
                        </v-col>
                        <v-col md="6">
                            <div v-show="video.url" id="plyr-wrapper">
                                <video class="plyr" id="player" playsinline controls :data-poster="video.thumbnail_url">
                                    <source :src="video.url" type="video/mp4"/>
                                </video>
                                <v-btn class="mt-2" color="red" elevation="2" @click="removeVideo">Remove</v-btn>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="text-center">
                            <v-btn @click="handleSave">Save</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </admin-dashboard-layout>
</template>

<script>
    import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
    import Video from "../../../models/Video";
    import vue2Dropzone from "vue2-dropzone";
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    import Plyr from "plyr";
    import Tag from "@/models/Tag";
    import Industry from "@/models/Industry";
    import {mapMutations} from "vuex";
    import languages from "@/configs/languages";

    export default {
        name: "Form",
        components: {AdminDashboardLayout, vueDropzone: vue2Dropzone},
        data: function () {
            return {
                languages,
                video: {},
                dropzoneOptions: {
                    url: '/api/upload',
                    maxFilesize: 1000,
                    headers: {
                        Authorization: null
                    },
                    params: {'directory': 'videos'}
                },
                thumbnailDropzoneOptions: {
                    url: '/api/upload',
                    maxFilesize: 5,
                    maxFiles: 1,
                    addRemoveLinks: true,
                    params: {'directory': 'videos/thumbnails'},
                    headers: {
                        Authorization: null
                    }
                },
                player: {},
                tags: [],
                industries: [],
                loading: true
            }
        },
        async mounted() {
            const token = await localStorage.getItem('auth_token_default')
            this.dropzoneOptions.headers.Authorization = "Bearer "+token
            this.thumbnailDropzoneOptions.headers.Authorization = "Bearer "+token

            if (this.$route.params.id) {
                const videoId = this.$route.params.id
                this.video = await Video.include(['tags']).find(videoId);
                this.player = new Plyr('#player');
            } else {
                let nextRank = await window.axios.get('/api/helpers/next-rank/videos');
                this.video.rank = nextRank.data
                this.$forceUpdate()
            }

            await this.getTags();
            await this.getIndustries();
            this.loading = false;
        },
        methods:{
            async handleSave() {
                if (this.$_.isArray(this.video.tags)) {
                    this.video.tags = this.video.tags.map((tag) => this.$_.isObject(tag) ? tag.id : tag);
                }
                const video = new Video(this.video)
                this.video = await video.save()
                this.showSnackBar({color: 'success', timeout: 3000, text: 'Saved'});
                if (this.video.id && ! this.$route.params.id) {
                    await this.$router.push({name: 'admin.videos.show', params: {id: this.video.id}})
                }
            },
            ...mapMutations(['showSnackBar']),
            handleSetUrl(file, res) {
                this.video.url = res.url
                this.video.path = res.path
            },

            handleSetThumbnailUrl(file, res) {
                this.video.thumbnail_url = res.url
            },

            handleExceededFilesLimit(file) {
                this.$refs.thumbnailVueDropzone.removeFile(file);
                this.showSnackBar({color: 'error', timeout: 3000, text: 'File limit exceeded'});
            },

            removeVideo() {
                this.video.url = '';
                this.video.path = '';
                this.video.thumbnail_url = '';
            },

            async getTags() {
                this.tags = await Tag.get();
            },

            async getIndustries() {
                this.industries = await Industry.get();

                if (!this.video.industry_id) {
                    this.video.industry_id = this.industries[0].id
                }
            }
        },
    }
</script>

<style lang="scss">
    @import "~plyr/src/sass/plyr";
    .plyr {
        height: 100%;
        width :100%;
    }
</style>
